<template>
  <div class="progress-wrapper d-flex align-center mb-1 px-2">
    <div class="task-progress-container">
      <div class="task-progress-content">
        <div class="task-title" @click="$emit('openDetailView', task)">
          <v-icon v-if="task.is_private" x-small class="mr-1">
            mdi-lock
          </v-icon>
          <router-link
            :to="{ name: 'tasks-detail', params: { taskId: task.id } }"
            :target="targetBlank ? '_blank' : '_self'"
            class="task-link"
          >
            #{{ task.number }} {{ task.title }}
          </router-link>
          <v-spacer/>
          <span class="ml-2" >{{ taskProgress }}%</span>
        </div>
      </div>
      <div
        class="bar"
        :style="{
          backgroundColor: `var(--v-${taskColor}-base)`,
          width: `${taskProgress}%`,
        }"
      />
    </div>
    <div class="dates">
      <span class="flex-nowrap mr-1">{{ formatStartDate }}</span>
      <span class="flex-nowrap">to {{ formatEndDate }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment';
import TaskHelper from '@/services/task-helper';

export default {
  name: 'TaskProgress',
  props: {
    task: {
      type: Object,
      required: true,
    },
    taskProgress: {
      type: Number,
      required: true,
    },
    targetBlank: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formatStartDate() {
      return moment(this.task?.planned_start || this.task.created_at).format(
        'DD-MM-YYYY'
      );
    },
    formatEndDate() {
      return moment(this.task?.planned_end || this.task.due).format(
        'DD-MM-YYYY'
      );
    },
    taskColor() {
      if (this.taskProgress >= 100) return 'success';
      if (this.taskProgress > 15) return 'warning';
      return 'error';
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 4px;
}
.dates {
  display: flex;
  font-size: 10px;
  white-space: nowrap;
  align-items: center;
}
.progress-wrapper {
  column-gap: 5px;
  height: 40px;
}
.task-title {
  overflow: hidden;
  display: flex;
  flex: 1;
  font-size: 14px;
}
.task-link {
  transition: 200ms;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
  }
}
.task-progress-container {
  position: relative;
  background-color: #d6d8dc;
  border-radius: 4px;
  overflow: hidden;
  flex-grow: 1;
}
.task-progress-content {
  position: relative;
  display: flex;
  column-gap: 5px;
  align-items: center;
  padding: 5px 10px;
  z-index: 5;
}
::v-deep .v-input--selection-controls__input {
  margin-right: 0;
}
</style>
