const MainDataListOptions = [
  {
    field: 'buildsystem',
    labelKey: 'modules.projectsPlanning.fieldNames.buildSystem',
    type: 'text',
  },
  {
    field: 'module_count',
    labelKey: 'modules.projectsPlanning.fieldNames.modules',
    type: 'integer',
    inputType: 'number',
  },
  {
    field: 'type',
    labelKey: 'modules.projectsPlanning.fieldNames.type',
    type: 'text',
  },
  {
    field: 'user_function',
    labelKey: 'modules.projectsPlanning.fieldNames.userFunction',
    type: 'text',
  },
  {
    field: 'budget_forecast',
    labelKey: 'modules.projectsPlanning.fieldNames.budgetForecast',
    type: 'text',
  },
  {
    field: 'permit_responsible',
    labelKey: 'modules.projectsPlanning.fieldNames.permitResponsible',
    type: 'text',
  },
];

const CustomerDataOptions = [
  {
    field: 'customer_company',
    labelKey: 'modules.projectsPlanning.fieldNames.customerCompany',
    type: 'text',
  },
  {
    field: 'customer_contact',
    labelKey: 'modules.projectsPlanning.fieldNames.customerContact',
    type: 'text',
  },
];

const BooleanBadges = [
  {
    field: 'temporary',
    labelKey: 'modules.projectsPlanning.fieldNames.temporary',
  },
  {
    field: 'WKB',
  },
];

const FieldTypes = {
  project_id: 'text',
  temporary: 'boolean',
  WKB: 'boolean',
  buildsystem: 'text',
  unit_count: 'integer',
  type: 'text',
  user_function: 'text',
  budget_forecast: 'text',
  permit_responsible: 'text',
  customer_company: 'text',
  customer_contact: 'text',
  status: 'text-field',
  red_flags: 'text-field',
};

export { MainDataListOptions, CustomerDataOptions, FieldTypes, BooleanBadges };
